import { computed } from "vue";
import { useFeatureFlags } from "~/composables/useFeatureFlags";

export function useAB() {
  const featureFlags = useFeatureFlags();

  const skipPersonalizedMotivationScreen = computed(() => featureFlags.personalized_motivation_screen.isOff().value);

  const showPartnerFunnelSecondPageDiscountBanner = computed(
    () => featureFlags.partner_funnel_second_page_discount_banner.isOn().value,
  );

  const skipBenefitScreen = computed(() => featureFlags.benefits_screen.isOff().value);

  const showPaywallPaymentHeadingVariant = computed(() => featureFlags.paywall_payment_heading.isOn().value);

  return {
    skipPersonalizedMotivationScreen,
    showPartnerFunnelSecondPageDiscountBanner,
    skipBenefitScreen,
    showPaywallPaymentHeadingVariant,
  };
}
